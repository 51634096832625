import Toast from "../../helpers/toast";
import { useDispatch } from "react-redux";
import { setCredentials, logoutSlice } from "../../features/slices/authSlice";
import { useNavigate } from "react-router-dom";
import { getUserApi } from "../../apis/auth/getUserApi";
import { loginApi } from "../../apis/auth/loginApi";
import { WaitListApi } from "../../apis/auth/WaitListApi";
import { RegisterApi, checkDataApi } from "../../apis/auth/RegisterApi";
import { ForgotPasswordApi } from "../../apis/auth/ForgotPasswordApi";
import { ResetPasswordApi } from "../../apis/auth/ResetPasswordApi";
import { logoutApi } from "../../apis/auth/logoutApi";
import { handleErrorApi } from "../../helpers/handleErrorApi.js";
import { SignUpSetErrors } from "../../helpers/HandleSetErrors/SignUpSetErrors";
import { checkSubscriptionApi } from "../../apis/auth/useAuthApi";

const useAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const login = async (data, setLoading) => {
    try {
      setLoading(true);
      const response = await loginApi(data);
      const token = await response.data.data.token;
      dispatch(
        setCredentials({
          token: token,
          user: response.data.data.user,
          permissions: response.data.data.user.permissions,
        })
      );
      // Toast("success", "You have successfully logged in");
      setLoading(false);
      navigate("/");
    } catch (error) {
      setLoading(false);
      handleErrorApi(error?.response?.status, error?.response?.data?.error);
    }
  };
  const getUserInfo = async (setLoading) => {
    try {
      setLoading(true);
      const token = localStorage.getItem("cod-dash-token");
      if (token) {
        const response = await getUserApi();
        const user = response.data.data;
        const permissions = response.data.data.permissions;
        dispatch(
          setCredentials({ token: token, user: user, permissions: permissions })
        );
        if (user.is_agent !== true) {
          if (user.is_activated === false) {
            navigate("/confirme_account");
          } else {
            if (user.store === null) {
              navigate("/create_store");
            }
          }
        }
      }
    } catch (error) {
      setLoading(false);
      localStorage.removeItem("cod-dash-token");
      navigate("/login");
    } finally {
      setLoading(false);
    }
  };
  const Register = async (data, setLoading, setError) => {
    try {
      setLoading(true);
      const response = await RegisterApi(data);
      Toast("success", "You have successfully Sign up, Please Sign In");
      setLoading(false);
      navigate("/login");
    } catch (error) {
      setLoading(false);
      if (error?.response.status === 400) {
        const data = error?.response.data.error;
        SignUpSetErrors(data, setError);
      } else {
        handleErrorApi(
          error?.response.status,
          error?.response.data?.message,
          error?.response.data?.error
        );
      }
    }
  };
  const forgotPassword = async (
    data,
    setStep,
    setError,
    setLoading,
    setDataUser
  ) => {
    try {
      setLoading(true);
      const response = await ForgotPasswordApi(data);
      setDataUser(response?.data?.data);
      setStep(1);
      Toast(
        "success",
        "Otp sent successfully to your email, please check your email"
      );
    } catch (error) {
      setStep(0);
      setLoading(false);
      setError("username", {
        type: "submit",
        message: error.response?.data?.message,
      });
    } finally {
      setLoading(false);
    }
  };
  const resetPassword = async (data, setError, setLoading) => {
    try {
      setLoading(true);
      const response = await ResetPasswordApi(data);
      Toast("success", response?.data?.message);
      navigate("/login");
    } catch (error) {
      setLoading(false);
      switch (error.response?.data?.error) {
        case "user_not_found":
          Toast("error", "The provided credentials are incorrect");
          break;
        case "token_not_found":
          setError("token", {
            type: "submit",
            message: error.response?.data?.message,
          });
          break;
        case "token_expired":
          setError("token", {
            type: "submit",
            message: error.response?.data?.message,
          });
          break;
        case "password_exist":
          setError("password", {
            type: "submit",
            message: error.response?.data?.message,
          });
          break;
      }
    } finally {
      setLoading(false);
    }
  };
  const WaitList = async (data, setLoading,setStep) => {
    try {
      setLoading(true);
      const response = await WaitListApi(data);
      Toast("success", "You have successfully Sign up in our WaitList");
      setLoading(false);
      setStep(1);
    } catch (error) {
      setLoading(false);
      handleErrorApi(
        error?.response.status,
        error?.response.data?.message,
        error?.response.data?.error
      );
    }
  };
  const logout = async () => {
    try {
      await logoutApi();
      dispatch(logoutSlice());
      Toast("success", "You have successfully logged out");
      navigate(0);
    } catch (error) {
      Toast("error", `${error?.response?.data?.message}`);
    }
  };
  const checkSubscription = async (setData) => {
    try {
      const response = await checkSubscriptionApi();
      setData(response.data);
    } catch (error) {
      handleErrorApi(
        error?.response.status,
        error?.response.data?.message,
        error?.response.data?.error
      );
    }
  };
  const checkData = async (data, setError, setLoading) => {
    try {
      setLoading(true);
      const response = await checkDataApi(data);
      if (response.data.data?.success === false) {
        switch (response.data.data?.type) {
          case "phone":
            setError("phone", {
              type: "submit",
              message: response.data.data?.message,
            });
            break;
          case "email":
            setError("email", {
              type: "submit",
              message: response.data.data?.message,
            });
            break;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  return {
    login,
    getUserInfo,
    Register,
    forgotPassword,
    resetPassword,
    WaitList,
    logout,
    checkSubscription,
    checkData,
  };
};

export default useAuth;
