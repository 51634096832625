import { useFormContext } from "react-hook-form";
import { errorMessage } from "../../../helpers/errorMessage";
import Loader from "../../../components/common/general/Loader";
import SelectComponent from "../../../components/FormUpdate/SelectComponent";
import { Link } from "react-router-dom";

const FirstPageWaitList = ({ onSubmit, loading }) => {
  const methods = useFormContext();
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = methods;
  return (
    <>
      <h5 className="text-muted fw-normal mb-4">
        Welcome! Sign up now in our Wait List.
      </h5>
      <form className="forms-sample" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-lg-6">
            <div className="mb-3">
              <label htmlFor="first_name" className="form-label">
                First name
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="First name"
                autoComplete="off"
                {...register("first_name", { required: true })}
              />
              {errorMessage(errors, "first_name")}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="mb-3">
              <label htmlFor="last_name" className="form-label">
                Last name
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Last name"
                autoComplete="off"
                {...register("last_name", { required: true })}
              />
              {errorMessage(errors, "last_name")}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="mb-3">
              <label htmlFor="Email" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                autoComplete="off"
                {...register("email", { required: true })}
              />
              {errorMessage(errors, "email")}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="mb-3">
              <label htmlFor="phone" className="form-label">
                Phone number
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Phone number"
                autoComplete="off"
                {...register("phone", { required: true })}
              />
              {errorMessage(errors, "phone")}
            </div>
          </div>
          <div className="col-lg-6">
            <SelectComponent
              data={[
                {
                  value: "google_sheet",
                  label: "Google Sheet",
                },
                {
                  value: "shopify",
                  label: "Shopify",
                },
                {
                  value: "youcan",
                  label: "YouCan",
                },
                {
                  value: "woocommerce",
                  label: "WooCommerce",
                },
                {
                  value: "others",
                  label: "Others",
                },
              ]}
              control={control}
              name="stores"
              closeMenuOnSelect
              label="Stores"
              isMulti={true}
            />
            {errorMessage(errors, "stores")}
          </div>
          <div className="col-lg-6">
            <SelectComponent
              data={[
                {
                  value: "yalidine",
                  label: "Yalidine",
                },
                {
                  value: "3mexpress",
                  label: "3M Express",
                },
                {
                  value: "zrexpress",
                  label: "ZR Express",
                },
                {
                  value: "maystro",
                  label: "Maystro Delivery",
                },
                {
                  value: "noest",
                  label: "Noest Express",
                },
                {
                  value: "ecotrack",
                  label: "Ecotrack",
                },
                {
                  value: "others",
                  label: "Others",
                },
              ]}
              control={control}
              name="delivery_company"
              closeMenuOnSelect
              label="Delivery Company"
              isMulti={true}
            />
            {errorMessage(errors, "delivery_company")}
          </div>
        </div>
        <div className="mt-3">
          {loading ? (
            <button
              type="button"
              disabled
              className="btn btn-primary me-2 mb-2 mb-md-0 text-white"
            >
              <Loader />
            </button>
          ) : (
            <button
              onClick={handleSubmit(onSubmit)}
              className="btn btn-primary me-2 mb-2 mb-md-0 text-white"
            >
              Send Informations
            </button>
          )}
        </div>
        <Link to="/login" className="d-block mt-3 text-muted">
          Already have account? SignIn now
        </Link>
      </form>
    </>
  );
};
export default FirstPageWaitList;
