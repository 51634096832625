import { useRoutes } from "react-router-dom";
import { routes } from "./routes";
import { routeAgent } from "./routeAgent";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useAuth from "./hooks/auth/useAuth";
import { setPermissions } from "./features/slices/authSlice";
import Loader from "./components/common/general/Loader";
import Loading from "./pages/Loading";
import ConfirmeAccount from "./pages/app/ConfirmeAccount/ConfirmeAccount";
import CreateStore from "./pages/app/store/CreateStore";
import ConnectionStatus from "./pages/ConnectionStatus";

function App() {
  const dispatch = useDispatch();
  const { user, isLoggedIn } = useSelector((state) => state.auth);
  const [accountActivate, setAccountActivate] = useState(false);
  const [createStore, setCreateStore] = useState(false);
  const { getUserInfo } = useAuth();
  const token = localStorage.getItem("cod-dash-token");
  const routing = useRoutes(routes(token, accountActivate, createStore));
  const routingAgent = useRoutes(routeAgent(token));

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUserInfo(setLoading);
    if (user !== null) {
      setAccountActivate(user.is_activated);
      if (user.store === null) {
        setCreateStore(true);
      }
      dispatch(setPermissions());
    }
  }, [isLoggedIn]);
  if (loading === true) {
    return <Loading />;
  } else {
    if (user !== null) {
      if (user.is_agent == true) {
        return <>{routingAgent}</>;
      } else {
        if (user.is_activated === false) {
          return (
            <>
              <ConfirmeAccount />
            </>
          );
        }
        if (user.store === null) {
          return (
            <>
              <CreateStore />
            </>
          );
        }
        return <>{routing}</>;
      }
    }
  }
}

export default App;
