import { useEffect, useState } from "react";
import useAgentLeads from "../../../hooks/agent/Leads/useAgentLeads";
import { useForm, Controller } from "react-hook-form";
import Loader from "../../../components/common/general/Loader";
import Toast from "../../../helpers/toast";
import { SelectedProduct, onSubmitFunction } from "./addProductUtils";
import { closePopup } from "../../../helpers/PopUpFunction";

const AddProductPopup = ({
  delivery_type,
  wilaya_id,
  daira_id,
  id,
  assign_to,
  setData,
  deliveryType,
}) => {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const { getLeadProducts, getLeadAddProducts } = useAgentLeads();
  useEffect(() => {
    if (
      delivery_type !== "" &&
      delivery_type !== null &&
      delivery_type !== undefined &&
      wilaya_id !== "" &&
      wilaya_id !== null &&
      wilaya_id !== undefined &&
      daira_id !== "" &&
      daira_id !== null &&
      daira_id !== undefined
    ) {
      var data = {
        wilaya_id: wilaya_id,
        daira_id: daira_id,
        delivery_type: delivery_type,
        lead_id: id,
        assign_to: assign_to,
      };
      getLeadProducts(setProducts, data);
    }
  }, [wilaya_id, daira_id, delivery_type, assign_to]);
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    register,
    setError,
  } = useForm({
    defaultValues: {
      selectedProductId: "",
      hasItems: false,
      qty: null,
      items: [],
    },
  });
  const selectedProductId = watch("selectedProductId");
  useEffect(() => {
    SelectedProduct(products, selectedProductId, setValue, setSelectedProduct);
  }, [selectedProductId, products, setValue]);
  const onSubmit = (data) => {
    onSubmitFunction(
      data,
      selectedProduct,
      selectedProductId,
      getLeadAddProducts,
      id,
      setLoading,
      setData,
      closePopup
    );
  };
  return (
    <>
      <div className="modal fade" tabIndex="-1" id="AddProductLead">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              {deliveryType === "Delivery Man" ? <>Delivery Man</> : <>non</>}
              <h5 className="modal-title">Add Product</h5>
              <button
                type="button"
                className="btn-close"
                onClick={(e) => closePopup("AddProductLead")}
              ></button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                {deliveryType === "Delivery Man" && products.length === 0 ? (
                  <div className="mb-2">
                    <div className="alert alert-warning" role="alert">
                      Delivery Man has no products in stock
                    </div>
                  </div>
                ) : null}
                <div className="mb-2">
                  <label className="form-label">Product</label>
                  <Controller
                    name="selectedProductId"
                    control={control}
                    render={({ field }) => (
                      <select {...field} className="form-control">
                        <option value="">
                          {loadingProduct ? "Loading..." : "Select a product"}
                        </option>
                        {products.map((product) => (
                          <option key={product.id} value={product.id}>
                            {product.name}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                  {errors.selectedProductId && (
                    <p className="text-danger mt-2 d-flex flex-row align-items-center">
                      {errors.selectedProductId.message}
                    </p>
                  )}
                </div>
                {selectedProduct && !selectedProduct.items?.length && (
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="mb-2">
                        <label className="form-label">Variant</label>
                        <input
                          className="form-control"
                          type="text"
                          defaultValue={selectedProduct.qty_reste}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="mb-2">
                        <div>
                          <label className="form-label">Quantity</label>
                          <Controller
                            name="qty"
                            control={control}
                            render={({ field }) => (
                              <input
                                className="form-control"
                                type="number"
                                {...field}
                              />
                            )}
                          />
                          {errors.qty && (
                            <p className="text-danger mt-2 d-flex flex-row align-items-center">
                              {errors.qty.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {selectedProduct && selectedProduct.items?.length > 0 && (
                  <div className="mb-2">
                    {selectedProduct.items.map((item, index) => (
                      <div key={item.id} className="row">
                        <input
                          type="text"
                          value={item.id}
                          {...register(`items.${index}.item_id`)}
                          hidden
                        />
                        <div className="col-lg-4">
                          <label className="form-label">Variant</label>
                          <input
                            className="form-control"
                            type="text"
                            defaultValue={item.name}
                            disabled
                          />
                        </div>
                        <div className="col-lg-4">
                          <label className="form-label">Variant</label>
                          <input
                            className="form-control"
                            type="text"
                            defaultValue={item.qty_reste}
                            disabled
                          />
                        </div>
                        <div className="col-lg-4">
                          <label className="form-label">Quantity</label>
                          <Controller
                            name={`items.${index}.qty`}
                            control={control}
                            defaultValue={item.qty}
                            render={({ field }) => (
                              <input
                                className="form-control"
                                type="number"
                                {...field}
                              />
                            )}
                          />
                          {errors.items?.[index]?.qty && (
                            <p className="text-danger mt-2 d-flex flex-row align-items-center">
                              {errors.items[index].qty.message}
                            </p>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={(e) => closePopup("AddProductLead")}
                >
                  Close
                </button>
                {loading ? (
                  <button type="submit" className="btn btn-success me-2">
                    <Loader />
                  </button>
                ) : (
                  <button type="submit" className="btn btn-success me-2">
                    Save
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddProductPopup;
