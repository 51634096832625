import React from "react";
import { Col, Row } from "react-bootstrap";
import Dropzone from "react-dropzone";
import ImageCard from "./ImageCard";
import { errorMessage } from "../../../helpers/errorMessage";

const ImageDropzone = ({
  multiple = false,
  placeholder,
  previewCol = 12,
  setValue,
  name,
  photos,
  errors,
  error = null,
  label = null
}) => {
  const handleDrop = (droppedFiles) => {
    const newPhotos = [];
    const existingPhotos = photos || [];
    if (multiple) {
      droppedFiles.forEach((file) => {
        newPhotos.push(file);
      });
      const updatedPhotos = existingPhotos.concat(newPhotos);
      setValue(name, updatedPhotos);
    } else {
      setValue(name, [droppedFiles[0]]);
    }
  };

  return (
    <>
      <Row md={12} className="d-flex flex-row align-items-start">
        <Col md={7}>
          <label className="form-label">{label === null ? "Image" : label }</label>
          <Dropzone onDrop={handleDrop}>
            {({ getRootProps, getInputProps }) => (
              <div
                style={{
                  height: "10rem",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "12px",
                }}
                {...getRootProps({ className: "dropzone" })}
              >
                <input
                  className="input-zone bg-secondary h-75"
                  {...getInputProps({
                    multiple: multiple,
                  })}
                />
                <p className="dropzone-content">{placeholder}</p>
              </div>
            )}
          </Dropzone>
          {errorMessage(errors, "image")}
        </Col>
        {photos?.length > 0 ? (
          <Col
            md={previewCol}
            className={`${previewCol === 6 ? "pt-0 pb-0" : "pt-2 pb-4 "}`}
          >
            <label className="form-label">Selected Images :</label>
            <div className="w-90 d-flex flex-row align-items-center justify-content-start flex-wrap gap-3">
              {photos?.map((file, key) => (
                <ImageCard
                  file={file}
                  onCLick={() => {
                    setValue(
                      name,
                      photos.filter((photo) => photo !== file)
                    );
                  }}
                />
              ))}
            </div>
          </Col>
        ) : null}
      </Row>
    </>
  );
};

export default ImageDropzone;
