import { handleErrorApi } from "../../../helpers/handleErrorApi";
import {
  getAgentLeadApi,
  getSuggessionUsersApi,
  getWilayasApi,
  getDairasApi,
  getSourcesApi,
  getLeadProductsApi,
  setLeadStatusApi,
  getLeadUpdateStatusApi,
  getCheckDeliveryApi,
  getConfirmeLeadApi,
  getgetCheckProductQtyApi,
  getUpdateLeadApi,
  getLeadAddProductsApi,
  getDeleteLeadItemApi
} from "../../../apis/agent/getAgentLeadsApis";
import { useNavigate } from "react-router-dom";

const useAgentLeads = () => {
  const navigate = useNavigate();

  const getLeadFunction = async (setLoading, setData) => {
    try {
      setLoading(true);
      const response = await getAgentLeadApi();
      setData(response.data?.data);
    } catch (error) {
      setLoading(false);
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    } finally {
      setLoading(false);
    }
  };
  const setSuggesionUsers = async (setData) => {
    try {
      const response = await getSuggessionUsersApi();
      setData(response.data?.data);
    } catch (error) {
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    }
  };
  const getWilayas = async (setLoading, setData) => {
    try {
      setLoading(true);
      const response = await getWilayasApi();
      setData(response.data?.data);
    } catch (error) {
      setLoading(false);
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    } finally {
      setLoading(false);
    }
  };
  const getDairas = async (id, setLoading, setData) => {
    try {
      setLoading(true);
      const response = await getDairasApi(id);
      setData(response.data?.data?.daira);
    } catch (error) {
      setLoading(false);
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    } finally {
      setLoading(false);
    }
  };
  const getSources = async (setLoading, setData) => {
    try {
      setLoading(true);
      const response = await getSourcesApi();
      setData(response.data?.data);
    } catch (error) {
      setLoading(false);
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    } finally {
      setLoading(false);
    }
  };
  const getLeadProducts = async (setProductDB,data) => {
    try {
      const response = await getLeadProductsApi(data);
      setProductDB(response.data?.data);
    } catch (error) {
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    }
  };
  const getUpdateStatus = async (id, data, setLoading,setData) => {
    try {
      setLoading(true);
      const response = await getLeadUpdateStatusApi(id, data);
      console.log(response.data?.data);
      setData(response.data?.data);
      // navigate(0);
    } catch (error) {
      setLoading(false);
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    } finally {
      setLoading(false);
    }
  };
  const getLeadStatus = async (id, setData, setLoading) => {
    try {
      setLoading(true);
      const response = await setLeadStatusApi(id);
      setData(response.data?.data);
    } catch (error) {
      setLoading(false);
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    } finally {
      setLoading(false);
    }
  };
  const getCheckDelivery = async (id, data, setDeliveryWarning,setDeliveryCost,setDeliveryCostAnou,setDeliveryType) => {
    try {
      const response = await getCheckDeliveryApi(id, data);
      if (response.data?.data?.exists === false) {
        setDeliveryWarning(
          <div className="row">
            <div className="alert alert-danger" role="alert">
            {response.data?.data?.message}
            </div>
          </div>
        );
        setDeliveryCost(0);
        setDeliveryCostAnou(0);
        setDeliveryType(null);
      } else {
        setDeliveryType(response.data?.data?.type);
        setDeliveryWarning(
          <div className="row">
            <div className="alert alert-warning" role="alert">
              Delivery fee is: {response.data?.data?.price} Da -{" "}
              {response.data?.data?.type}: {response.data?.data?.name}
            </div>
          </div>
        );
        setDeliveryCost(response.data?.data?.price);
        setDeliveryCostAnou(response.data?.data?.delivery_anounced);
      }
    } catch (error) {
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    }
  };
  const getConfirmeLead = async (id, data, setLoading,setData) => {
    try {
      setLoading(true);
      const response = await getConfirmeLeadApi(id, data);
      navigate(0);
    } catch (error) {
      setLoading(false);
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    } finally {
      setLoading(false);
    }
  };
  const getCheckProductQty = async (
    temp_data,
    setLoading,
    setProducts,
    products
  ) => {
    try {
      setLoading(true);
      const response = await getgetCheckProductQtyApi(temp_data);
      const product = response?.data?.data;
      let productTable = products;
      if (product.has_items === false) {
        productTable.push(product);
        setProducts(productTable);
      }
    } catch (error) {
      setLoading(false);
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    } finally {
      setLoading(false);
    }
  };
  const getUpdateLead = async (id, temp_data, setLoading, setData) => {
    try {
      setLoading(true);
      const response = await getUpdateLeadApi(id, temp_data);
      setData(response.data?.data);
    } catch (error) {
      setLoading(false);
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    } finally {
      setLoading(false);
    }
  };
  const getLeadAddProducts = async (id, temp_data, setLoading, setData) => {
    try {
      setLoading(true);
      const response = await getLeadAddProductsApi(id, temp_data);
      setData(response.data?.data);
    } catch (error) {
      setLoading(false);
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    } finally {
      setLoading(false);
    }
  }
  const getDeleteLeadItem = async (id, item_id, setLoading, setData) => {
    try {
      setLoading(true);
      const response = await getDeleteLeadItemApi(id, item_id);
      setData(response.data?.data);
    } catch (error) {
      setLoading(false);
      handleErrorApi(
        error?.response.status,
        error?.response.data.message,
        error?.response.data.error
      );
    } finally {
      setLoading(false);
    }
  }
  return {
    getLeadFunction,
    setSuggesionUsers,
    getWilayas,
    getDairas,
    getSources,
    getLeadProducts,
    getUpdateStatus,
    getLeadStatus,
    getCheckDelivery,
    getConfirmeLead,
    getCheckProductQty,
    getUpdateLead,
    getLeadAddProducts,
    getDeleteLeadItem
  };
};
export default useAgentLeads;
