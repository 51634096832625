import { Link } from "react-router-dom";
import { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import useAuth from "../../hooks/auth/useAuth";
import { yupResolver } from "@hookform/resolvers/yup";
import { errorMessage } from "../../helpers/errorMessage";
import Loader from "../../components/common/general/Loader";
import { WaitListSchema } from "../../validation/auth/WaitListSchema";
import SelectComponent from "../../components/FormUpdate/SelectComponent";
import FirstPageWaitList from "./WaitList/FirstPageWaitList";

const WaitList = () => {
  const { WaitList } = useAuth();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const methods = useForm({
    resolver: yupResolver(step === 0 ? WaitListSchema : null),
  });
  const { handleSubmit } = methods;
  const submitWaitList = async (data) => {
    console.log(data);
    WaitList(data, setLoading, setStep);
    // forgotPassword(data, setStep, methods.setError, setLoading, setDataUser);
  };
  const submitResetPassword = async (data) => {
    console.log(data);
  };
  return (
    <div className="main-wrapper">
      <div className="page-wrapper full-page">
        <div className="page-content d-flex align-items-center justify-content-center">
          <div className="row w-100 mx-0 auth-page">
            <div className="col-md-8 col-xl-7 mx-auto">
              <div className="card">
                <div className="row">
                  <div className="col-md-3 pe-md-0">
                    <div className="auth-side-wrapper"></div>
                  </div>
                  <div className="col-md-9 ps-md-0">
                    <div className="auth-form-wrapper px-4 py-5">
                      <p className="noble-ui-logo logo-light d-block mb-2">
                        Track <span>COD</span> Wait List
                      </p>
                    
                      <FormProvider {...methods}>
                        {step === 0 ? (
                          <FirstPageWaitList
                            setStep={setStep}
                            loading={loading}
                            onSubmit={handleSubmit(submitWaitList)}
                          />
                        ) : (
                          <>
                            <div className="alert alert-success" role="alert">
                              Votre demande a été envoyé avec succès{" "}
                            </div>
                          </>
                        )}
                      </FormProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WaitList;
